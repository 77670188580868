export default {
  /**
   * SETTINGS - API
   */
  settings: null,

  /**
   * AUTH - API
   */
  authOutdoor: null,

  /**
   * USER - API
   */
  userOutdoor: null,

  /**
   * BOX - API
   */
  boxOutdoor: null,

  /**
  * GLOBAL LOADING - API
  */
  globalLoadingOutdoor: false,

  /**
  * TRANSLATIONS - API
  */
  translationsOutdoor: null,
  i18nDataOutdoor: null,

  test: null,
  error: null,
};
